



































































































































@import '@design';

.wrapper {
    position: relative;
    display: flex;
    margin-top: 8px;
}
.input {
    @include taphighlight;
    @include unselectable;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: transparent;
    border: 0;
    outline: none;
    opacity: 0.01;
}
